<template>
	<div>
		<div class="ui-property">
			<div class="ui-property__count" :style="'background-image: url('+ propertyCountImg +');'">
				<div class="ui-property__text">资产总数(件)</div>
				<div class="ui-property__allNum">
					<span>{{assetsData?.assetsNum || ''}}</span>
				</div>
			</div>
			<div class="ui-property__count" :style="'background-image: url('+ propertyMomeyImg +');'">
				<div class="ui-property__text">资产总价值(元)</div>
				<div class="ui-property__allNum">
					<span>{{assetsData?.amount || ''}}</span>
				</div>
			</div>
		</div>
		<div class="ui-inventory__row">
			<div class="ui-inventory__item">
				<div class="ui-inventory__text">应盘资产(件)</div>
				<div class="ui-inventory__num">{{inventoryData?.num || ''}}</div>
			</div>
			<div class="ui-inventory__item">
				<div class="ui-inventory__text">已盘资产(件)</div>
				<div class="ui-inventory__num">{{inventoryData?.alreadyNum}}</div>
			</div>
			<div class="ui-inventory__item">
				<div class="ui-inventory__text">盘亏资产(件)</div>
				<div class="ui-inventory__num">{{inventoryData?.lossNum}}</div>
			</div>
			<div class="ui-inventory__item">
				<div class="ui-inventory__text">盘盈资产(件)</div>
				<div class="ui-inventory__num">{{inventoryData?.profitNum}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import propertyCountImg from '@/assets/image/jobs/property-count.png';
	import propertyMomeyImg from '@/assets/image/jobs/property-momey.png';
	export default {
		props: ['inventoryData','assetsData'],
		data() {
			return {
				propertyCountImg,
				propertyMomeyImg,
			}
		}
	}
</script>

<style scoped>
	.ui-property {
		display: flex;
		margin-top: 20px;
		/* flex-wrap: wrap; */
		justify-content: space-between;
	}
	.ui-property__count {
		width: 49%;
		height: 112px;
		padding: 20px 15px;
		background: #4982F2;
		border-radius: 5px;
		background-repeat: no-repeat;
		background-position: bottom right;
		background-size: contain;
	}
	.ui-property__text {
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 19px;
	}
	.ui-property__allNum {
		margin-top: 10px;
		font-size: 16px;
		font-weight: 500;
		color: #d1dffc;
		vertical-align: text-bottom;
	}
	.ui-property__allNum span {
		padding-right: 2px;
		font-size: 24px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 42px;
	}
	.ui-inventory__row {
		display: flex;
		margin-top: 20px;
		justify-content: space-between;
		/* flex-wrap: wrap; */
	}
	.ui-inventory__item {
		/* width: 168px; */
		width: 23.5%;
		height: 98px;
		padding: 20px 0;
		margin-bottom: 10px;
		/* background: #EDF2FE; */
		border: solid 1px #E7E7E7;
		border-radius: 5px;
		text-align:center;
	}
	.ui-inventory__num {
		margin-top: 10px;
		font-size: 24px;
		font-weight: bold;
		color: #4982F2;
		line-height: 28px;
	}
	.ui-inventory__text {
		font-size: 14px;
		font-weight: 400;
		color: #303A5D;
		line-height: 16px;
	}
</style>