<template>
	<div>
		<h3 class="ui-header">国有资产数据分析</h3>
		<div class="ui-year">{{currentTask.name}}</div>
		<a-row>
			<a-col :span="12" style="padding-right: 30px;">
				<div v-permission="['pc_statistics_assets']">
					<topHeader text="资产统计"></topHeader>
					<assetStatistics :inventoryData="inventoryData.head" :assetsData="assetsData"></assetStatistics>
				</div>
				<div v-permission="['pc_statistics_inventory']" style="margin-top: 30px;">
					<topHeader text="盘点统计"></topHeader>
					<div style="margin-top: 20px;">
						<inventoryStatistics :invenStatusData="invenStatusData"></inventoryStatistics>
					</div>
				</div>
			</a-col>
			<div class="ui-line">
				<a-divider type="vertical" style="width: 1px;height: 100%;background: #F0F0F0;" />
			</div>
			<a-col :span="12" style="padding-left: 30px;">
				<a-row>
					<a-col :span="15" style="padding-right: 10px;">
						<div v-permission="['pc_statistics_efficiency']">
							<topHeader text="盘点效率统计"></topHeader>
							<div style="margin-top: 20px;">
								<efficiency :unitData="unitData"></efficiency>
							</div>
						</div>
					</a-col>
					<a-col :span="9" style="padding-left: 10px;">
						<div v-permission="['pc_statistics_circulate']" class="ui-circulate">
							<div class="ui-circulate__name">
								<img src="@/assets/image/statistics/icon.png" />
								资产流通
							</div>
							<div class="ui-circulate__item">
								<div class="ui-circulate__num">{{analysisData.transferNum}}</div>
								<div class="ui-circulate__text">调拨资产(件)</div>
							</div>
							<div class="ui-circulate__item">
								<div class="ui-circulate__num">{{analysisData.leaseNum}}</div>
								<div class="ui-circulate__text">借入借出(件)</div>
							</div>
						</div>
					</a-col>
				</a-row>
				<a-col :span="24" style="margin-top: 30px;">
					<div v-permission="['pc_statistics_ranking']">
						<topHeader text="高效单位排行"></topHeader>
						<div style="margin-top: 20px;">
							<ranking :unitRank="unitRank"></ranking>
						</div>
					</div>
				</a-col>
			</a-col>
		</a-row>
	</div>
</template>

<script>
	import { getMyAssets, getMyInventory } from '@/service/modules/jobs.js';
	import { getUnitInventoryDetail, getFiscalAnalysis, getCurrentTask } from '@/service/modules/statistics.js';
	// import { getAllUnitList } from '@/service/modules/global.js';
	import topHeader from '@/views/jobs/components/topHeader.vue';
	import inventoryStatistics from './components/inventoryStatistics.vue';
	import assetStatistics from './components/assetStatistics.vue';
	import efficiency from './components/efficiency.vue';
	import ranking from './components/ranking.vue';
	export default {
		components: { topHeader, assetStatistics, efficiency, ranking, inventoryStatistics },
		data() {
			return {
				assetsData: {},
				inventoryData: {},
				invenStatusData: {},
				currentTask: {},
				analysisData: {},// 右侧统计数据
				unitData: {} ,// 单位总数和逾期单位
				unitRank: [] // 单位排行数据
			}
		},
		created() {
			this.getDataAssets()
			this.getDataInventory()
			this.getUnitInventoryStatus()
			this.getFiscalAnalysisData()
			this.getCurrentTaskFun()
		},
		methods: {
			async getDataAssets() {
				try {
					let ret = await getMyAssets({});
					if (ret.code === 200) {
						this.assetsData = ret.data;
					}
				} catch(e) {
					
				}
			},
			async getCurrentTaskFun() {
				try {
					let ret = await getCurrentTask({});
					if (ret.code === 200) {
						this.currentTask = ret.data;
					}
				} catch(e) {
					
				}
			},
			async getDataInventory() {
				try {
					let ret = await getMyInventory({});
					if (ret.code === 200) {
						this.inventoryData = ret.data;
					}
				} catch(e) {
				}
			},
			async getUnitInventoryStatus() {
				try {
					let ret = await getUnitInventoryDetail({});
					if (ret.code === 200) {
						this.invenStatusData = ret.data;
					}
				} catch(e) {
					
				}
			},
			async getFiscalAnalysisData() {
				try {
					let ret = await getFiscalAnalysis({});
					if (ret.code === 200) {
						this.analysisData = ret.data;
						this.unitData.mangerUnitNum = ret.data.mangerUnitNum
						this.unitData.outOfDateNum = ret.data.outOfDateNum
						this.unitData.onTime = ret.data.onTime;
						this.unitRank = ret.data.unitTaskNumVoList.slice(0,10);
					}
				} catch(e) {
					
				}
			},
		}
	}
</script>

<style scoped>
	.ui-header {
		padding: 0;
		font-size: 34px;
		font-family: Noto Sans SC-Medium, Noto Sans SC;
		font-weight: 500;
		color: #333333;
		line-height: 40px;
		text-align: center;
	}
	.ui-year {
		margin-bottom: 30px;
		font-size: 18px;
		font-family: Noto Sans SC-Medium, Noto Sans SC;
		font-weight: 500;
		color: #666666;
		line-height: 21px;
		text-align: center;
	}
	.ui-line {
		height: calc(100% - 80px);
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
	.ui-circulate {
		height: 100%;
		padding: 24px 20px;
		border-radius: 12px;
		background-color: #edf2fe;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: bottom right;
		background-image: url('../../assets/image/statistics/bg-icon.png');
	}
	.ui-circulate__name {
		display: flex;
		align-items: center;
		font-size: 18px;
		font-weight: 500;
		color: #333333;
	}
	.ui-circulate__name img {
		width: 44px;
		height: 44px;
		margin-right: 8px;
	} 
	.ui-circulate__item {
		margin-top: 35px;
	}
	.ui-circulate__num {
		font-size: 36px;
		font-family: DIN Alternate-Bold, DIN Alternate;
		font-weight: bold;
		color: #4982F2;
		line-height: 24px;
	}
	.ui-circulate__text {
		margin-top: 20px;
		font-size: 14px;
		font-family: Noto Sans SC-Regular, Noto Sans SC;
		font-weight: 400;
		color: #303A5D;
		line-height: 20px;
	}
	
	@media screen and (min-width: 1200px) and (max-width: 1600px) {
		.ui-circulate__name {
			font-size: 16px;
		}
		
		.ui-circulate__name img {
			width: 28px;
			height: 28px;
		}
	}
</style>