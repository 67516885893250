<template>
	<div>
		<div id="inventoryChart"></div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	let eChartsInstall;
	export default {
		props: ['invenStatusData'],
		mounted() {
			this.onChartDraw()
		},
		watch: {
			invenStatusData: {
				handler(newval,oldval) {
						this.$nextTick(() => {
							this.onChartDraw();
						})
				},
				deep: true,
				immediate:true
			}
		},
		methods: {
			onChartDraw() {
				var chartDom = document.getElementById('inventoryChart');
				echarts.init(chartDom).dispose();
				eChartsInstall = echarts.init(chartDom);
				let options = {
					grid: {
						left: '3%',
						right: '4%',
						top: '8%',
						bottom: '14%',
						containLabel: true
					},
					legend: {
						bottom: 0,
					},
					tooltip: {
						trigger: 'item'
					},
					series: [{
						name: '盘点统计',
						type: 'pie',
						radius: ['40%', '70%'],
						itemStyle: {
						    borderRadius: 10,
						    borderColor: '#fff',
						    borderWidth: 2
						},
						label: {
						    show: true,
							formatter(param) {
								return param.value;
							}
						},
						data: [
							{ value: this.invenStatusData.completed, name: "已完成" },
							{ value: this.invenStatusData.inProgress, name: "进行中" },
							{ value: this.invenStatusData.notStart, name: "未开始" },
						]
					}]
				};
				this.$nextTick(() => {
					eChartsInstall.setOption(options);
					this.resize();
				})
			},
			resize() {
				if (this.isListener) return;
				window.addEventListener('resize', (e) => {
					if (this.timeOut) {
						clearTimeout(this.timeOut);
					}
					this.timeOut = setTimeout(() => {
						this.timeOut = null;
						eChartsInstall.resize();
					}, 300);
				}, true);
				this.isListener = true;
			},
		}
	}
</script>

<style scoped>
	#inventoryChart {
		height: 300px;
	}
</style>