<template>
	<div>
		<div id="rankChart"></div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	let eChartsInstall;
	export default {
		data() {
			return {
				// unitRank1: []
			}
		},
		props: ['unitRank'],
		mounted() { 
			// console.log("uniNameData",this.unitRank,this.uniNameData) // 初始化时为空
			this.onChartDraw()
		},
		computed: {
			uniNameData() { // 单位名
				let tempArr = []
				this.unitRank.forEach(item => { 
					tempArr.push(item.unitName)
				})
				return tempArr
			},
			lossAssetsData() { // 盘亏
				let tempArr = []
				this.unitRank.forEach(item => {
					tempArr.push(item.lossAssets)
				})
				return tempArr
			},
			alreadyCheckData() { // 应盘
				let tempArr = []
				this.unitRank.forEach(item => {
					tempArr.push(item.alreadyCheck)
				})
				return tempArr
			}
		},
		watch: {
			unitRank: {
				handler(newval,oldval) {
					// console.log("111",newval)
						// this.unitRank1 = newval // data中定义的变量
						this.$nextTick(() => {
							this.onChartDraw();
						})
				},
				deep: true,
				immediate:true
			}
		},
		methods: {
			onChartDraw() {
				// let xData = ['教研股', '人事股', '教学股', '师顺股', '后勤办', '政教股', '办公室', '计财股', '校检股', '体育办'];
				let xData = this.uniNameData;
				var chartDom = document.getElementById('rankChart');
				echarts.init(chartDom).dispose();
				eChartsInstall = echarts.init(chartDom);
				let options = {
					grid: {
						left: '3%',
						right: '4%',
						top: '8%',
						bottom: xData.length > 10 ? '20%' : '14%',
						containLabel: true
					},
					legend: {
						bottom: 0,
					},
					tooltip: {
					    trigger: 'axis',
					    axisPointer: {
					      // Use axis to trigger tooltip
					      type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
					    }
					},
					xAxis: {
						type: 'category',
						// boundaryGap: false,
						data: xData,
						axisLabel: {
							interval:0,
							formatter:function(value){ // 类目轴标题换行
								var str = "";
								var num = 9;//每行显示字数
								var valLength = value.length;//该项x轴字数
								var rowNum = Math.ceil(valLength/num);//行数
								if(rowNum >1) {
									for(var i=0;i<rowNum;i++){
										var temp= "";
										var start = i * num;
										var end = start + num;
										temp =value.substring(start,end) + "\n";
										str += temp;
									}
									return str;
								} else {
									return value;
								}
							}
						}
					},
					yAxis: {
						type: 'value'
					},
					series: [{
							name: '已盘资产',
							type: 'bar',
							color: '#75EFE6',
							barWidth: 10,
							// stack: 'Total',
							// data: [10, 20, 15, 20, 5, 8, 16, 22, 9, 10]
							data: this.alreadyCheckData
						}, {
							name: '盘亏资产',
							type: 'bar',
							barWidth: 10,
							color: '#4982F2',
							// stack: 'Total',
							emphasis: {
								focus: 'series'
							},
							// data: [320, 100, 200, 150, 250, 140, 190, 280, 210, 170]
							data: this.lossAssetsData
						}
					]
				};
				if (xData.length > 10) {
					options.dataZoom = [{
							type: 'inside',
							minValueSpan: 7,
							maxValueSpan: 10,
							zoomLock: true,
							zoomOnMouseWheel: false
						},
						{
							height: 20,
							minValueSpan: 7,
							maxValueSpan: 10,
							zoomLock: true,
							zoomOnMouseWheel: false
						}
					]
				}
				this.$nextTick(() => {
					eChartsInstall.setOption(options);
					this.resize();
				})
			},
			resize() {
				if (this.isListener) return;
				window.addEventListener('resize', (e) => {
					if (this.timeOut) {
						clearTimeout(this.timeOut);
					}
					this.timeOut = setTimeout(() => {
						this.timeOut = null;
						eChartsInstall.resize();
					}, 300);
				}, true);
				this.isListener = true;
			},
		}
	}
</script>

<style scoped>
	#rankChart {
		height: 300px;
	}
</style>