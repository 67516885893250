<template>
	<div>
		<a-row>
			<a-col :span="11" style="padding-right: 8px;">
				<div class="ui-box">
					<div class="ui-box__num">{{unitData?.mangerUnitNum || 0}}</div>
					<div class="ui-box__text">单位总数（家）</div>
				</div>
				<div class="ui-box">
					<div class="ui-box__num">{{unitData?.outOfDateNum || 0}}</div>
					<div class="ui-box__text">逾期单位（家）</div>
				</div>
			</a-col>
			<a-col :span="13" style="padding-left: 8px;">
				<div class="ui-box" style="height: 100%;">
					<a-progress type="circle" trailColor="#dbe6fc" :width="140" :strokeWidth="8" :percent="unitData.onTime" :format="format" />
					<div class="ui-box__text" style="margin-top: 20px;">及时率</div>
				</div>
			</a-col>
		</a-row>
	</div>
</template>

<script>
	export default {
		props: ['unitData'],
		methods: {
			format(val) {
				return val + '%';
			}
		}
	}
</script>

<style scoped>
	.ui-box {
		padding: 30px 0;
		background: #FFFFFF;
		border-radius: 5px;
		opacity: 1;
		text-align: center;
		border: 1px solid #E7E7E7;
	}
	.ui-box + .ui-box {
		margin-top: 30px;
	}
	.ui-box__num {
		font-size: 24px;
		font-family: DIN Alternate-Bold, DIN Alternate;
		font-weight: bold;
		color: #4982F2;
		line-height: 28px;
		text-align: center;
	}
	.ui-box__text {
		margin-top: 10px;
		font-size: 14px;
		font-family: Noto Sans SC-Regular, Noto Sans SC;
		font-weight: 400;
		color: #303A5D;
		line-height: 16px;
		text-align: center;
	}
</style>